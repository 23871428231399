var render = function () {
  var _vm$courses, _vm$lectures, _vm$courses2, _vm$lectures2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trainings-section"
  }, [_c('profile-section-wrapper', {
    attrs: {
      "title": "تمكين وتدريب"
    }
  }, [_c('div', {
    staticClass: "trainings-wrapper"
  }, [(_vm$courses = _vm.courses) !== null && _vm$courses !== void 0 && _vm$courses.length ? _c('div', {
    staticClass: "courses-wrapper"
  }, [_c('span', [_vm._v("تدريب")]), _vm._l(_vm.courses, function (course, key) {
    return _c('div', {
      key: ("course-" + key),
      staticClass: "training"
    }, [_c('p', [_vm._v(_vm._s(course.courseName))]), _c('p', [_vm._v("نسبة الدوام: " + _vm._s(course.attendancePercentage || '-'))])]);
  })], 2) : _vm._e(), (_vm$lectures = _vm.lectures) !== null && _vm$lectures !== void 0 && _vm$lectures.length ? _c('div', {
    staticClass: "lectures-wrapper"
  }, [_c('span', [_vm._v("محاضرة")]), _vm._l(_vm.lectures, function (lecture, key) {
    return _c('div', {
      key: ("lecture-" + key),
      staticClass: "training"
    }, [_c('p', [_vm._v(_vm._s(lecture.lectureName))]), _c('p', [_vm._v(_vm._s(lecture.date))])]);
  })], 2) : _vm._e()]), !((_vm$courses2 = _vm.courses) !== null && _vm$courses2 !== void 0 && _vm$courses2.length) && !((_vm$lectures2 = _vm.lectures) !== null && _vm$lectures2 !== void 0 && _vm$lectures2.length) ? _c('p', {
    staticClass: "empty"
  }, [_vm._v("لا يوجد محاضرات او تدريبات")]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }