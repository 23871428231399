<template>
  <div class="statistics-section">
    <div class="row justify-content-center">
      <div class="col-6">
        <div class="statistic">
          <p>{{ statistics?.loyalty?.points ?? 0 }}</p>
          <p>عدد النقاط</p>
        </div>
      </div>
      <div class="col-6">
        <div class="statistic">
          <p>{{ statistics?.profile?.volunteerInfo?.numberOfVolunteerHours ?? 0 }}</p>
          <p>عدد ساعات التطوع</p>
        </div>
      </div>
      <div class="col-12">
        <div class="statistic">
          <p>{{ statistics?.profile?.volunteerInfo?.numberOfAttendancesZadi ?? 0 }}</p>
          <p>جلسات زادي</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statistics: {
      type: Object,
      default: () => null
    },
  },
};
</script>

<style scoped>
.row {
  margin-left: -8px;
  margin-right: -8px;
}

.col-12,
.col-6,
.col-sm-12,
.col-sm-6,
.col-md-6 {
  padding: 8px;
}

.statistic {
  text-align: center;
  border: 2px solid #9DD8D4;
  border-radius: 10px;
  padding: 12px;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
  min-height: 88px;
}

.statistic p:nth-child(1) {
  /* line-height: 1;
  background: linear-gradient(180deg, #F4ACBF, #9DD8D4);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #F4ACBF;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 8px;
}

.statistic p:nth-child(2) {
  font-weight: 600;
  font-size: 16px;
  color: #979797;
}
</style>