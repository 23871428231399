<template>
  <div class="trainings-section">
    <profile-section-wrapper title="تمكين وتدريب">
      <div class="trainings-wrapper">
        <div class="courses-wrapper" v-if="courses?.length">
          <span>تدريب</span>
          <div class="training" v-for="course, key in courses" :key="`course-${key}`">
            <p>{{ course.courseName }}</p>
            <p>نسبة الدوام: {{ course.attendancePercentage || '-' }}</p>
          </div>
        </div>
        <div class="lectures-wrapper" v-if="lectures?.length">
          <span>محاضرة</span>
          <div class="training" v-for="lecture, key in lectures" :key="`lecture-${key}`">
            <p>{{ lecture.lectureName }}</p>
            <p>{{ lecture.date }}</p>
          </div>
        </div>
      </div>
      <p class="empty" v-if="!courses?.length && !lectures?.length">لا يوجد محاضرات او تدريبات</p>
    </profile-section-wrapper>
  </div>
</template>

<script>
import ProfileSectionWrapper from "../ProfileSectionWrapper.vue";

export default {
  components: { ProfileSectionWrapper },
  props: {
    lectures: {
      type: Array,
      default: () => [],
    },
    courses: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.trainings-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}

.lectures-wrapper,
.courses-wrapper {
  padding-top: 8px;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
  position: relative;
}

.courses-wrapper {
  border: 2px solid #9DD8D4;
}

.lectures-wrapper {
  border: 2px solid #f4acbf;
}

.lectures-wrapper span,
.courses-wrapper span {
  position: absolute;
  top: -14px;
  right: 50%;
  transform: translate(50%);
  border-radius: 12px;
  padding: 2px 12px;
  font-size: 15px;
  font-weight: 600;
  color: white;
}

.lectures-wrapper span {
  background: #f4acbf;
}

.courses-wrapper span {
  background: #9DD8D4;
}

.training {
  color: #979797;
  display: block;
  font-weight: 500;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}

.training:not(:last-child) {
  border-bottom: 2px solid #E5E5E5;
}

.empty {
  color: #979797;
  font-weight: 600;
  font-size: 0.9rem;
}

@media (max-width: 480px) {
  .training {
    gap: 4px;
    flex-direction: column;
  }
}
</style>