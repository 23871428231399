var render = function () {
  var _vm$statistics$loyalt, _vm$statistics, _vm$statistics$loyalt2, _vm$statistics$profil, _vm$statistics2, _vm$statistics2$profi, _vm$statistics2$profi2, _vm$statistics$profil2, _vm$statistics3, _vm$statistics3$profi, _vm$statistics3$profi2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "statistics-section"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "statistic"
  }, [_c('p', [_vm._v(_vm._s((_vm$statistics$loyalt = (_vm$statistics = _vm.statistics) === null || _vm$statistics === void 0 ? void 0 : (_vm$statistics$loyalt2 = _vm$statistics.loyalty) === null || _vm$statistics$loyalt2 === void 0 ? void 0 : _vm$statistics$loyalt2.points) !== null && _vm$statistics$loyalt !== void 0 ? _vm$statistics$loyalt : 0))]), _c('p', [_vm._v("عدد النقاط")])])]), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "statistic"
  }, [_c('p', [_vm._v(_vm._s((_vm$statistics$profil = (_vm$statistics2 = _vm.statistics) === null || _vm$statistics2 === void 0 ? void 0 : (_vm$statistics2$profi = _vm$statistics2.profile) === null || _vm$statistics2$profi === void 0 ? void 0 : (_vm$statistics2$profi2 = _vm$statistics2$profi.volunteerInfo) === null || _vm$statistics2$profi2 === void 0 ? void 0 : _vm$statistics2$profi2.numberOfVolunteerHours) !== null && _vm$statistics$profil !== void 0 ? _vm$statistics$profil : 0))]), _c('p', [_vm._v("عدد ساعات التطوع")])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "statistic"
  }, [_c('p', [_vm._v(_vm._s((_vm$statistics$profil2 = (_vm$statistics3 = _vm.statistics) === null || _vm$statistics3 === void 0 ? void 0 : (_vm$statistics3$profi = _vm$statistics3.profile) === null || _vm$statistics3$profi === void 0 ? void 0 : (_vm$statistics3$profi2 = _vm$statistics3$profi.volunteerInfo) === null || _vm$statistics3$profi2 === void 0 ? void 0 : _vm$statistics3$profi2.numberOfAttendancesZadi) !== null && _vm$statistics$profil2 !== void 0 ? _vm$statistics$profil2 : 0))]), _c('p', [_vm._v("جلسات زادي")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }