var render = function () {
  var _vm$effects;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "effects-section"
  }, [_c('profile-section-wrapper', {
    attrs: {
      "title": "بصمتي المجتمعية"
    }
  }, [(_vm$effects = _vm.effects) !== null && _vm$effects !== void 0 && _vm$effects.length ? _c('div', {
    ref: "effectsSwiper",
    staticClass: "effects-swiper"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.effects, function (effect, key) {
    var _effect$evaluation;
    return _c('div', {
      key: ("effect-" + key),
      staticClass: "swiper-slide effect"
    }, [_c('p', [_vm._v(_vm._s(effect.type))]), _c('p', [_vm._v(_vm._s(effect.courseName))]), _c('p', [_vm._v(_vm._s((_effect$evaluation = effect.evaluation) !== null && _effect$evaluation !== void 0 ? _effect$evaluation : 'غير مقييم'))])]);
  }), 0)]) : _c('p', {
    staticClass: "empty"
  }, [_vm._v("لا يوجد بصمة لك")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }